<template>
  <section class="brands" id="brands">
    <div class="wrapper brands-wrapper">
      <h2>Marcas Representadas</h2>
      <!-- <p>Nam bibendum tempor maximus. Donec condimentum, diam eget porttitor tempus, nisl mi bibendum nunc. Nam bibendum tempor maximus tempus, nisl mi bibendum . </p> -->
      <div id="divBrandsHome" class="slider2">
        <v-slide-group show-arrows>
          <v-slide-item v-for="(brand, i) in brands" :key="i" class="ma-6">
            <a :href="brand.url" target="_blank"
              ><img alt="logo-marca-representada" :src="img_url + brand.image"
            /></a>
          </v-slide-item>
        </v-slide-group>
      </div>
    </div>
  </section>
</template>

<script>
import computedMixins from "@/mixins/computed";

export default {
  mixins: [computedMixins],
  props:["brands"],
  data() {
    return {
    };
  },
  computed: {
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";

.brands {
  padding: 60px 0 10px 0;
  @include responsive(0, 512px) {
    padding: 40px 0 50px 0;
  }
  .wrapper {
    &.brands-wrapper{
      @include responsive(0, 512px){
        margin-right:20px;
        margin-left:20px;
      }
    }
    h2 {
    }
    p {
      width: 50%;
      text-align: center;
      margin: 0 auto;
      margin-bottom: 50px;
      @include responsive(0, 512px) {
        width: 100%;
        margin-bottom: 0px;
      }
    }
    .slider2 {
      position: relative;
      width: 100%;
        a {
        width: 190px;
        @include responsive(0, 512px){
          width: calc(100vw - 190px);
        }
          img {
            width: 100%;
            vertical-align: middle;
            margin: 0 auto;
            margin-top: 50%;
            transform: translateY(-50%);
            &.height {
              width: auto;
              height: 180px;
            }
          }
        }
    }
  }
}
</style>