<template>
  <section
    class="loader"
    :class="loader ? '' : 'close'"
    id="loader"
    style="z-index: 101"
  >
    <div class="pattern">
      <div class="row type1">
        <img class="icon" :src="img_url + 'icon-loop1.png'" />
        <img class="icon reverse" :src="img_url + 'icon-loop2.png'" />
        <img class="icon" :src="img_url + 'icon-loop1.png'" />
        <img class="icon reverse" :src="img_url + 'icon-loop2.png'" />
      </div>
      <div class="row">
        <img class="icon reverse" :src="img_url + 'icon-loop2.png'" />
        <img class="icon" :src="img_url + 'icon-loop1.png'" />
        <img class="icon reverse" :src="img_url + 'icon-loop2.png'" />
        <img class="icon" :src="img_url + 'icon-loop1.png'" />
      </div>
      <div class="row type1">
        <img class="icon" :src="img_url + 'icon-loop1.png'" />
        <img class="icon reverse" :src="img_url + 'icon-loop2.png'" />
        <img class="icon" :src="img_url + 'icon-loop1.png'" />
        <img class="icon reverse" :src="img_url + 'icon-loop2.png'" />
      </div>
      <div class="row">
        <img class="icon reverse" :src="img_url + 'icon-loop2.png'" />
        <img class="icon" :src="img_url + 'icon-loop1.png'" />
        <img class="icon reverse" :src="img_url + 'icon-loop2.png'" />
        <img class="icon" :src="img_url + 'icon-loop1.png'" />
      </div>
      <div class="row">
        <img class="icon reverse" :src="img_url + 'icon-loop2.png'" />
        <img class="icon" :src="img_url + 'icon-loop1.png'" />
        <img class="icon reverse" :src="img_url + 'icon-loop2.png'" />
        <img class="icon" :src="img_url + 'icon-loop1.png'" />
      </div>
      <div class="row">
        <img class="icon reverse" :src="img_url + 'icon-loop2.png'" />
        <img class="icon" :src="img_url + 'icon-loop1.png'" />
        <img class="icon reverse" :src="img_url + 'icon-loop2.png'" />
        <img class="icon" :src="img_url + 'icon-loop1.png'" />
      </div>
      <div class="row">
        <img class="icon reverse" :src="img_url + 'icon-loop2.png'" />
        <img class="icon" :src="img_url + 'icon-loop1.png'" />
        <img class="icon reverse" :src="img_url + 'icon-loop2.png'" />
        <img class="icon" :src="img_url + 'icon-loop1.png'" />
      </div>
      <div class="row">
        <img class="icon reverse" :src="img_url + 'icon-loop2.png'" />
        <img class="icon" :src="img_url + 'icon-loop1.png'" />
        <img class="icon reverse" :src="img_url + 'icon-loop2.png'" />
        <img class="icon" :src="img_url + 'icon-loop1.png'" />
      </div>
    </div>
    <!-- <p>A carregar...</p> -->
    <img class="logo" :src="img_url + 'logo-white.png'" />
  </section>
</template>

<script>
import computedMixins from "@/mixins/computed";

export default {
  mixins: [computedMixins],
  props: ["loader"],
};
</script>

<style  lang="scss" scoped>
@import "@/assets/scss/style.scss";

.close {
  display: none !important;
}
.loader {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #c82027;
  text-align: center;
  overflow: hidden;
}
.loader .pattern {
  position: absolute;
  top: -30%;
  left: -20%;
  width: 150%;
}
.loader .pattern .type1 {
  margin-left: -12.5%;
}
.loader .pattern .icon {
  width: 25%;
  margin: 0px;
  animation: rotate 10s;
  animation-iteration-count: infinite;
  top: 0;
}
.loader .pattern .icon.reverse {
  animation-direction: reverse;
  padding-top: -30px;
}
.loader p {
  color: white;
  font-weight: 700;
  text-align: center;
  font-size: 20px;
  margin-top: 20px;
  font-family: "Kodchasan", sans-serif;
  position: absolute;
  top: 57%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.loader .logo {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300px;
  transform: translate(-50%, -50%);
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>